import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// DOM
const $html = document.documentElement
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const setLoadState = loading => {
    if(loading) {
        $html.classList.add('is-loading')
    } else {
        $html.classList.remove('is-loading')
    }
}

/**
 * Models
 */

const modelPaths = [
    '/models/pl.glb',
    '/models/sacha.glb',
]

const totalModels = modelPaths.length
let currentIndex = 0

const gltfLoader = new GLTFLoader()

const models = []

for(let i = 0; i < totalModels; i++) {
    models.push({
        glb: null,
        isLoaded: false,
        onLoad: () => {}
    })
}

let model
const loadModel = index => {
    return new Promise(resolve => {
        gltfLoader.load(modelPaths[index], gltf => {
            model = models[index]
            model.glb = gltf
            model.isLoaded = true
            gltf.scene.rotation.y = 2.5
            gltf.scene.scale.set(2, 2, 2)
            gltf.scene.visible = index === 0
            model.onLoad()
            console.log('loaded', modelPaths[index])

            scene.add(gltf.scene)
            resolve()
        })
    })
}

// Load current model then load the rest
loadModel(0).then(() => {
    setLoadState(false)
    for(let i = 1; i < totalModels; i++) {
        if(i !== currentIndex) {
            loadModel(i)
        }
    }
})

// modelPaths.forEach((model, i) => {
//     gltfLoader.load(model, gltf => {
//         models[i].glb = gltf
//         models[i].isLoaded = true
//         gltf.scene.scale.set(2, 2, 2)
//         gltf.scene.visible = i === currentIndex

//         scene.add(gltf.scene)
//     })
// })

/**
 * Floor
 */
// const floor = new THREE.Mesh(
//     new THREE.PlaneGeometry(10, 10),
//     new THREE.MeshStandardMaterial({
//         color: '#444444',
//         metalness: 0,
//         roughness: 0.5
//     })
// )
// floor.receiveShadow = true
// floor.rotation.x = - Math.PI * 0.5
// scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(2, 2, 2)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()



/**
 * Navigation
 */

// Previous
const previous = () => {
    console.log(currentIndex)
    toggleModel((currentIndex - 1 + totalModels) % totalModels)
}

// Next
const next = () => {
    toggleModel((currentIndex + 1) % totalModels)
}

const toggleModel = newIndex => {

    if(models[currentIndex].isLoaded) {
        models[currentIndex].glb.scene.visible = false
    }

    currentIndex = newIndex

    if(!models[newIndex].isLoaded) {
        setLoadState(true)
        models[newIndex].onLoad = () => {
            console.log('on load')
            setLoadState(false)
            models[newIndex].glb.scene.visible = currentIndex === newIndex
        }
    } else {
        models[newIndex].glb.scene.visible = true
    }
}


// DOM
const $btnPrev = document.getElementById('prev')
const $btnNext = document.getElementById('next')

$btnPrev.addEventListener('click', previous)
$btnNext.addEventListener('click', next)
